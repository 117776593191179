import React, { useState, useEffect } from 'react';
import DropDown from '../components/UI/DropDown';
import useApi from '../api/useApi';
import AddNewCourse from "./popups/AddNewCourse";
import AddNewSubjects from "./popups/AddNewSubjects" ;
import LoadingPopup from "./popups/LoadingPopup";
import { GET_All_Courses , Create_Questions } from '../api/urls';
// import AddClass from '../components/ClassSection/AddClass';
// import AddSection from '../components/ClassSection/AddSection';

function CreateCourse() {
    const [addCourse, setCourse] = useState(false);
    const [addSubject, setSubject] = useState(null);
    const [listCourses, setlistCourses] = useState([]);
    const [CoursesRes, getCourses] = useApi();
    const [createQuestionRes, createQuestion] = useApi();
    // 
    useEffect(() => {
      getCourses(GET_All_Courses, 'GET');
    }, []);
    // 
    useEffect(() => {
      if( !createQuestionRes.isLoading){
          let AlertMsg = "" ;
          if( createQuestionRes.isError || createQuestionRes.data?.Error) { 
            AlertMsg = 'Something unexpected happans.'
          } else if (  createQuestionRes.data?.GptSettingsNotEnable ){
            AlertMsg = 'ChatGpt Settings are not Enabled.'
          } else if ( createQuestionRes.data?.Res_Not_serialized ) {
            AlertMsg = 'Error in parsing chat GPT response.\nPlease try again.'
          } else if( createQuestionRes.data?.noError ){
            AlertMsg = 'Questions generated and saved successfully.'
          }
         // 
         if( AlertMsg != "" ){
          setTimeout(() => {
            alert(AlertMsg );   
          }, 900);
         }
         if( createQuestionRes.data?.Error || createQuestionRes.data?.noError ){
          getCourses(GET_All_Courses, 'GET');
         }
      }
    }, [createQuestionRes]);
    //
    useEffect(() => {
      if (!CoursesRes.isLoading && !CoursesRes.isError && CoursesRes.data?.noError && CoursesRes.data?.AllCoures) {
        setlistCourses(CoursesRes.data.AllCoures);
      }
      if( !CoursesRes.isLoading && CoursesRes.data?.Deleted ){
        alert('Data Deleted Successfully.');   
      }
    }, [CoursesRes]);
    //
    const handleSubject = (sub) => {  // console.log(" subjects click : ", sub )
      setSubject(sub) ;
    }
    const handleDelete = (CursDet ) => { 
      getCourses(GET_All_Courses, 'POST', {ID : CursDet._id } );
    }
    const handlequestions = (CursDet) => {  
      createQuestion(Create_Questions, 'POST', {ID : CursDet._id } );
    }
//   const [institutes, setInstitutes] = useState([]);
//   const [selectedInstitution, selectInstitution] = useState();
//   const [classes, setClasses] = useState();
//   const [addClass, setAddClass] = useState(false);
//   const [addSection, setAddSection] = useState(false);

//   const [instituteRes, getInstitutes] = useApi();
//   const [classesRes, getClasses] = useApi();
//   const [deleteClassRes, deleteClass] = useApi();

//   useEffect(() => {
//     getInstitutes(GET_INSTITUTES, 'GET');
//   }, []);

//   useEffect(() => {
//     if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
//       setInstitutes(instituteRes.data.response);
//     }
//   }, [instituteRes]);

//   useEffect(() => {
//     if (selectedInstitution) {
//       getClasses(GET_CLASSES(selectedInstitution), 'GET');
//     }
//   }, [selectedInstitution]);

//   useEffect(() => {
//     if (!classesRes.isLoading && !classesRes.isError && classesRes.data) {
//       setClasses(classesRes.data);
//     }
//   }, [classesRes]);

//   const handleCloseClass = () => {
//     setAddClass(false);
//   };

//   const refreshList = () => {
//     getClasses(GET_CLASSES(selectedInstitution), 'GET');
//   };

//   const handleDeleteClass = (_class) => {
//     deleteClass(DELETE_CLASSES(_class._id), 'DELETE', { instituteId: selectedInstitution });
//   };

//   useEffect(() => {
//     if (!deleteClassRes.isLoading && !deleteClassRes.isError && deleteClassRes.status == 201) {
//       alert('Class Deleted successfully');
//       refreshList();
//     }

//     if (!deleteClassRes.isLoading && deleteClassRes.isError) {
//       alert('Something went wrong');
//     }
//   }, [deleteClassRes]);
  //  let classes = [{name:"DevOps Online Certification Course" }] ;  
  //  console.log(" check : listCourses : ", listCourses )
  return (
    <>
      {createQuestionRes.isLoading && <LoadingPopup text={'Generating Questions..'} />} 
        { addSubject && <AddNewSubjects onClose={() => setSubject(null)} subjectSelected={addSubject} /> }
        { ( !addSubject && addCourse) && ( <AddNewCourse onClose={() => setCourse(false)} modifyCourseID={addCourse} reloadpage={()=> getCourses(GET_All_Courses, 'GET') } /> ) }
      {/* {addClass && (
        <AddClass onClose={handleCloseClass} refreshList={refreshList} selectedInstitution={selectedInstitution} />
      )}

      {addSection && (
        <AddSection
          onClose={() => setAddSection(null)}
          selectedInstitution={selectedInstitution}
          selectedClass={addSection}
        />
      )} */}

      <div className="w-12/12 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>Courses</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            {/* <div className="w-full">
              <DropDown
                width={'w-1/3'}
                title={'Select Institution'}
                data={["test"]}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => {
                  // selectInstitution(x._id);
                }}
                margin={'mt-4 mx-auto'}
              />
            </div> */}
            {CoursesRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
            ) : listCourses ? (
              <div className="w-full py-4">
                <button
                  className="border py-2 ml-auto w-40 mb-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
                  onClick={() => setCourse("0")}
                >
                  Add Course
                </button>
                <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-2/4 border-r-2 py-2 px-2">Course Name</div>
                      <div className="w-2/4 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="showScroll" style={{ maxHeight: '60vh' }}>
                    {Array.isArray(listCourses) && 
                      listCourses.map((x, idx) => {
                        let courseCat = x?.courseCategory && x?.courseCategory != "" ? "[ "+ x?.courseCategory + " ] " : "" ;
                        return (
                          <div className="w-full">
                            <div
                              className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                                idx !== listCourses.length - 1 && 'border-b border-borderYellow'
                              }`}
                            >
                              <div className="w-2/4 px-2 text-textDark ">{courseCat}{x?.courseName}</div>

                              <div className={`w-2/4 px-2 flex`}> 
                                
                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                  // onClick={() => {
                                  //   // setAddSection(x);
                                  // }}
                                  onClick={() => setCourse(x?._id)}
                                >
                                  View & Edit
                                </button> 
                               

                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                  onClick={() => {
                                    handleDelete(x);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                  onClick={() => {
                                    handleSubject(x);
                                  }}
                                >
                                  Subjects
                                </button>
                                <button
                                  className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                  onClick={() => {
                                    handlequestions(x);
                                  }}
                                >
                                  Gen. Questions
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) :  null
            // (
            //   <p className="w-full text-center text-xl font-semibold my-10 text-primary">
            //     Select Institution To Get Classes
            //   </p>
            // )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateCourse ;
