import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../api/useApi';
import { CareateCourse , POST_SubjectTopic } from '../../api/urls';
import { jsx } from 'react/jsx-runtime';


function AddNewSubjects({ onClose, subjectSelected}) { 
  const [selectedSubject, selectSubject] = useState(0);
  const [selectedChapter, selectChapter] = useState(0);
  const [selectedTopic, setSelectedTopic ] = useState(0);
  const [CourseName, setCourseName ] = useState("");
  const [formData, setFormData] = useState({ new_subject : '' , new_chapter : '', chapter_weight:"" , new_topic : '', new_subtop : '' });
  const [subjectListServer, setsubjectListServer ] = useState([]);
  // 
  const [chapterList_render, set_chapter_List] = useState([]); 
  const [topicsList_render, set_topics_List] = useState([]); 
  const [SubtopicList_render, set_Subtopic_List] = useState([]); 
  const [SelSubjectId_render, set_SelSubject_Id] = useState("0"); 
  //
  const [SubjectTopicRes, SaveLoadSubjectTopic] = useApi();
  //
  useEffect(() => { 
    if( subjectSelected ){ // console.log(" -- subjectSelected : ", subjectSelected );
      if( subjectSelected.courseName && subjectSelected.courseName != "" ){ setCourseName( " in course : " + subjectSelected.courseName );  }
      if( subjectSelected._id && subjectSelected._id != ""){
        let ReqData = { dataType : '0' , dataStr: "", ID:subjectSelected._id }
        SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
      } 
    }
  },[])
  //
  useEffect(() => {  // index change by click on row
    CalCulate() ;
  },[selectedSubject,selectedChapter,selectedTopic])
  //
  useEffect(() => { 
    if (!SubjectTopicRes.isLoading && !SubjectTopicRes.isError && SubjectTopicRes.data?.noError) {
      setsubjectListServer( SubjectTopicRes.data?.AllSubjectsTopics );
      CalCulate(SubjectTopicRes.data?.AllSubjectsTopics) ;
    }
    if (!SubjectTopicRes.isLoading && (SubjectTopicRes.isError || SubjectTopicRes.data?.Error) ) {
      alert('Something unexpected happans.');  
    } else if( !SubjectTopicRes.isLoading && SubjectTopicRes.data?.isSaved ){
      alert('Data Saved Successfully.');  
      // setFormData({ new_subject : '' , new_topic : '' });
    } else if( !SubjectTopicRes.isLoading && SubjectTopicRes.data?.isDeleted ){
      alert('Data Deleted Successfully.');   
    }
  },[SubjectTopicRes])
  
  const AddSubject = () => {
    if( formData.new_subject && formData.new_subject != "" ){
      let ReqData = { dataType : '1' , dataStr: formData.new_subject , ID:subjectSelected._id }
      SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
      setFormData({ ...formData, new_subject : '' });
    } else{
      alert("Please enter subject name.")
    }
  }
  const AddChapter = () => {
    let temp = subjectListServer
    if( Array.isArray(temp) && temp.length > selectedSubject ){
      let alertMsg = "" ;
      if( formData.new_chapter && formData.new_chapter != "" ){ } else{ alertMsg = alertMsg + "Please enter chapter name.\n"; }
      if( formData.chapter_weight && formData.chapter_weight != "" ){ } else{ alertMsg = alertMsg + "Please enter chapter weight.\n"; }
      if( alertMsg ){ alert(alertMsg); return ; }
      else {
        if( Array.isArray(temp[selectedSubject].ChapterArray )){
          temp[selectedSubject].ChapterArray.push( { topicArray : [] , name : formData.new_chapter, chap_weight : formData.chapter_weight  } )
        }else{
          temp[selectedSubject].ChapterArray = [ { topicArray : [] , name : formData.new_chapter, chap_weight : formData.chapter_weight } ]
        }
        setsubjectListServer( temp );
        CalCulate(temp) ;
        setFormData({ ...formData, new_chapter : '', chapter_weight:""  });
        UpdateServer( temp ) ;
      } 
    } else {
      alert("Please enter Subject.\n");
    } 
    // console.log(" -- temp : ", temp );
  }
  //
  const AddTopic = () => { 
    let alertMsg = "" ;
    if( formData.new_topic && formData.new_topic != "" ){ } else{ alertMsg = alertMsg + "Please enter topic name.\n"; } 
    if( alertMsg ){ alert(alertMsg); return ; }
    let temp = subjectListServer
    if( Array.isArray(temp[selectedSubject].ChapterArray ) && temp[selectedSubject].ChapterArray.length > selectedChapter ){
      if( Array.isArray(temp[selectedSubject].ChapterArray[selectedChapter].topicArray )){
        temp[selectedSubject].ChapterArray[selectedChapter].topicArray.push( { subTopArray : [] , name : formData.new_topic } )
      }else{
        temp[selectedSubject].ChapterArray[selectedChapter].topicArray = [ { subTopArray : [] , name : formData.new_topic } ]
      }
      setsubjectListServer( temp );
      CalCulate(temp) ;
      setFormData({ ...formData, new_topic : '' });
      UpdateServer( temp ) ;
    } else { 
      alert("Please enter Subject and Chapter.\n");
    }  
  }
  const AddSubTop = () => { 
    let alertMsg = "" ;
    if( formData.new_subtop && formData.new_subtop != "" ){ } else{ alertMsg = alertMsg + "Please enter sub topic name.\n"; } 
    if( alertMsg ){ alert(alertMsg); return ; }
    let temp = subjectListServer ; // console.log(" -- AddSubTop : ", temp ) ;
    if( Array.isArray(temp[selectedSubject].ChapterArray ) && temp[selectedSubject].ChapterArray.length > selectedChapter ){
      if( Array.isArray(temp[selectedSubject].ChapterArray[selectedChapter].topicArray ) && temp[selectedSubject].ChapterArray[selectedChapter].topicArray.length > selectedTopic ){
        if( Array.isArray( temp[selectedSubject].ChapterArray[selectedChapter].topicArray[selectedTopic].subTopArray ) ){
          temp[selectedSubject].ChapterArray[selectedChapter].topicArray[selectedTopic].subTopArray.push( { name : formData.new_subtop } )
        }else{
          temp[selectedSubject].ChapterArray[selectedChapter].subTopArray = [ { name : formData.new_subtop } ]
        }
        setsubjectListServer( temp );
        CalCulate(temp) ;
        setFormData({ ...formData, new_subtop : '' });
        UpdateServer( temp ) ;
      } else {
        alert("Please enter topic.\n");
      } 
    } else { 
      alert("Please enter Subject and Chapter and topic.\n")
    } 
  }
  //
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  
  //
  const DeleteChapter = (chap_index) => { 
    console.log(" deleting chapter : ", chap_index);
    let temp = subjectListServer ;
    if( Array.isArray(temp) && temp.length > selectedSubject ){
      if( Array.isArray(temp[selectedSubject].ChapterArray )){
        temp[selectedSubject].ChapterArray.splice(chap_index, 1); 
      } 
      setsubjectListServer( temp );
      CalCulate(temp) ; 
      UpdateServer( temp ) ;
      selectChapter(0);
      setSelectedTopic(0);
    } else {
      console.log("topic delete error on Subject.\n");
    } 
  }
  const DeleteTopic = (topic_index) => { 
    console.log(" deleting topic : ", topic_index);
    let temp = subjectListServer ;
    if( Array.isArray(temp[selectedSubject].ChapterArray ) && temp[selectedSubject].ChapterArray.length > selectedChapter ){
      if( Array.isArray(temp[selectedSubject].ChapterArray[selectedChapter].topicArray )){
        temp[selectedSubject].ChapterArray[selectedChapter].topicArray.splice(topic_index, 1);
      } 
      setsubjectListServer( temp );
      CalCulate(temp) ; 
      UpdateServer( temp ) ;
      setSelectedTopic(0);
    } else { 
      console.log("topic delete error on Chapter.\n")
    } 
  }
  const DeleteSubtopic = (SubTop_index) => { 
    console.log(" deleting subtopic : ", SubTop_index);
    let temp = subjectListServer ; // console.log(" -- AddSubTop : ", temp ) ;
    if( Array.isArray(temp[selectedSubject].ChapterArray ) && temp[selectedSubject].ChapterArray.length > selectedChapter ){
      if( Array.isArray(temp[selectedSubject].ChapterArray[selectedChapter].topicArray ) && temp[selectedSubject].ChapterArray[selectedChapter].topicArray.length > selectedTopic ){
        if( Array.isArray( temp[selectedSubject].ChapterArray[selectedChapter].topicArray[selectedTopic].subTopArray ) ){
          temp[selectedSubject].ChapterArray[selectedChapter].topicArray[selectedTopic].subTopArray.splice(SubTop_index, 1);
        } 
        setsubjectListServer( temp );
        CalCulate(temp) ; 
        // setFormData({ ...formData });
        UpdateServer( temp ) ;
      } else {
         console.log(" Sub topic delete error on topic.\n");
      } 
    } else { 
      console.log(" Sub topic delete error on Chapter and topic.\n")
    } 
  }
  // const ArrayRemoveIndex = ( dataArray , dataIndex ) => {
  //   // const index = dataArray.indexOf(5);
  //   if (dataIndex > -1) { // only splice dataArray when item is found
  //     dataArray.splice(dataIndex, 1); // 2nd parameter means remove one item only
  //   }
  //   return dataArray ;
  // }
  // 
  // let chapterList = [ ] ;
  // let topicsList = [ ] ; 
  // let SubtopicList = [ ] ;
  // let SelSubjectId = "0" ;
  const UpdateServer = (UpdatingData) => { 
    UpdatingData = UpdatingData ? UpdatingData : subjectListServer ;
    let Json_obj = "[]" 
    if( Array.isArray(UpdatingData) && UpdatingData.length > selectedSubject ){
      Json_obj = JSON.stringify( UpdatingData[selectedSubject].ChapterArray );
    } 
    if( UpdatingData.length > 0 &&  selectedSubject < UpdatingData.length ){
      let subjectId = UpdatingData[selectedSubject]._id ;
      let ReqData = { dataType : '2' , dataStr: Json_obj , ID:subjectId }
      SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
    } else {
      console.log("Error in updating server : incorrect data format.")
    }
  }
  const CalCulate = (UpdatingData) => { 
    UpdatingData = UpdatingData ? UpdatingData : subjectListServer ;
    // console.log(" --- CalCulate : ", UpdatingData.length , " -- ", selectedSubject )
    let forSafesubjectIndex = selectedSubject ; 
    let forSafeChapterIndex = selectedChapter ;
    let forSafeTopicIndex = selectedTopic ;
    let chapterList = [ ] ;
    let topicsList = [ ] ;
    let SubtopicList = [ ] ;
    let SelSubjectId = "0" ; 
    if( UpdatingData.length > 0 ){
      if( forSafesubjectIndex < UpdatingData.length ){ }
      else{ forSafesubjectIndex = UpdatingData.length-1 }
      chapterList = UpdatingData[forSafesubjectIndex].ChapterArray ;  SelSubjectId = UpdatingData[forSafesubjectIndex]._id ;
      if( chapterList.length > 0  ){
        if( forSafeChapterIndex < chapterList.length ){ }
        else{ forSafeChapterIndex = chapterList.length-1; }
        topicsList = UpdatingData[forSafesubjectIndex].ChapterArray[forSafeChapterIndex].topicArray
        if( topicsList.length > 0  ){
          if( forSafeTopicIndex < topicsList.length ){ }
          else{ forSafeTopicIndex = topicsList.length-1; }
          SubtopicList = UpdatingData[forSafesubjectIndex].ChapterArray[forSafeChapterIndex].topicArray[forSafeTopicIndex].subTopArray
        } 
      } 
      //
    }  
    set_chapter_List( chapterList ) ;
    set_topics_List( topicsList );
    set_Subtopic_List( SubtopicList );
    set_SelSubject_Id( SelSubjectId ) ;
    //
    selectSubject(forSafesubjectIndex) ;
    selectChapter(forSafeChapterIndex) ;
    setSelectedTopic(forSafeTopicIndex) ;
    // console.log("--- chapterList : ",chapterList ) ;
    // console.log("--- topicsList : ",topicsList ) ;
    // console.log("--- SubtopicList : ",SubtopicList ) ;
    // console.log(" -- subjectListServer updating to : ", UpdatingData );
  } 
  // console.log(" -- chapterList_render : ", chapterList_render );
  // console.log(" -- SubtopicList_render : ", SubtopicList_render );
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 pt-56"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-11/12 -mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          Subjects {CourseName}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        /> 
        <div className='flex flex-row  justify-between px-6' >
          <div className="mt-2 mb-4 ml-6 w-4/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex ">
              Subject
            </label>
            <input
              type="text"
              placeholder="Subject"
              autoComplete="off"
              value={formData.new_subject}
              name="new_subject"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={ AddSubject }
            >
              Add Subject
            </button>
          </div>  
          <div className="mt-2 mb-4 ml-6 w-4/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex w-32">
              Topic
            </label>
            <input
              type="text"
              placeholder="Topic"
              autoComplete="off"
              value={formData.new_topic}
              name="new_topic"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={AddTopic }
            >
              Add Topic
            </button>
          </div> 
        </div>
        <div className='flex flex-row  justify-between px-6' >
          <div className="mt-2 mb-4 ml-6 w-5/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex ">
              Chapter
            </label>
            <input
              type="text"
              placeholder="Chapter"
              autoComplete="off"
              value={formData.new_chapter}
              name="new_chapter"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <input
              type="text"
              placeholder="Weight"
              autoComplete="off"
              value={formData.chapter_weight}
              name="chapter_weight"
              onChange={handleInputChange}
              className="w-3/12 text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={AddChapter }
            >
              Add Chapter
            </button>
          </div> 
          <div className="mt-2 mb-4 ml-6 w-4/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex w-32">
              Sub Topic
            </label>
            <input
              type="text"
              placeholder="Sub Topic"
              autoComplete="off"
              value={formData.new_subtop}
              name="new_subtop"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={AddSubTop }
            >
              Add SubTopic
            </button>
          </div> 
        </div>
        <div className='flex flex-row my-4 mx-4 justify-around  '> 
          <div className='w-3/12 mr-3'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Subject Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
              {Array.isArray(subjectListServer) && 
                subjectListServer.map((sub, sub_index) => {
                  return (
                    <div className="w-full cursor-pointer" onClick={()=>{ selectSubject(sub_index); }}>
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                          sub_index !== subjectListServer.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-10/12 px-2 text-textDark ">{ selectedSubject == sub_index ? "[ Selected ] " : "" }{sub.SubjectName}</div>
                        <div className={`w-2/12 px-2 flex`}> 
                          <button
                            className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                            onClick={() => {
                              SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', { dataType : '3' , dataStr:'' , ID:sub._id } );
                            }}
                          >
                            Delete
                          </button> 
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              </div>
            </div>
          </div> 
          <div className='w-3/12  mr-3'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Chapter/Unit Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
                {Array.isArray(chapterList_render) && 
                  chapterList_render.map((chapterName, chapter_index) => {
                    return (
                      <div className="w-full cursor-pointer" onClick={()=>{ selectChapter(chapter_index); }}  >
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                            chapter_index !== chapterList_render.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-10/12 px-2 text-textDark ">{ selectedChapter == chapter_index ? "[ Selected ] " : "" } {chapterName.name}
                          { chapterName.chap_weight ? " [ weight : "+chapterName.chap_weight +"]": null }
                          </div>
                          <div className={`w-2/12 px-2 flex`}> 
                            <button
                              className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                              onClick={() => {
                                DeleteChapter(chapter_index);
                              }}
                            >
                              Delete
                            </button> 
                          </div>
                        </div>
                      </div>
                    );
                  })
                } 
              </div>
            </div>
          </div> 






          <div className='w-3/12  mr-3'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Topic Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
                {Array.isArray(topicsList_render) && 
                  topicsList_render.map((topic, topic_index) => {
                    return (
                      <div className="w-full cursor-pointer" onClick={()=>{ setSelectedTopic(topic_index); }}   >
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                            topic_index !== topicsList_render.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-10/12 px-2 text-textDark ">{ selectedTopic == topic_index ? "[ Selected ] " : "" }{topic.name}</div>
                          <div className={`w-2/12 px-2 flex`}> 
                            <button
                              className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                              onClick={() => {
                                DeleteTopic(topic_index);
                              }}
                            >
                              Delete
                            </button> 
                          </div>
                        </div>
                      </div>
                    );
                  })
                } 
              </div>
            </div>
          </div> 
          <div className='w-3/12'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Topic Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
                {Array.isArray(SubtopicList_render) && 
                  SubtopicList_render.map((SubtopicName, Subtopic_index) => {
                    return (
                      <div className="w-full cursor-pointer"  >
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                            Subtopic_index !== SubtopicList_render.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-10/12 px-2 text-textDark ">{SubtopicName.name}</div>
                          <div className={`w-2/12 px-2 flex`}> 
                            <button
                              className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                              onClick={() => {
                                DeleteSubtopic(Subtopic_index)
                              }}
                            >
                              Delete
                            </button> 
                          </div>
                        </div>
                      </div>
                    );
                  })
                } 
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default AddNewSubjects;
