import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { useStateValue } from '../../ContextApi/StateProvider';
// import { MY_DETAILS, GET_INSTITUTES, MASTER_DATA } from '../../api/urls';
import useApi from '../../api/useApi';

const Layout = ({ showLeftPannel , children }) => {
  // const history = useHistory();
  // const [{ token }, dispatch] = useStateValue();
  // const [{ isLoading, isError, data }, getUserDetail] = useApi();
  // const [instituteRes, getInstitutes] = useApi();
  // const [masterDataRes, getMasterData] = useApi();

  // useEffect(() => {
  //   if (token) {
  //     // getUserDetail(MY_DETAILS, 'GET');
  //     // getInstitutes(GET_INSTITUTES, 'GET');
  //     // getMasterData(MASTER_DATA, 'GET');
  //   }
  // }, [token]);

  // useEffect(() => {
  //   if (!isLoading && !isError && data) {
  //     dispatch({
  //       type: 'USER',
  //       user: data,
  //     });
  //   }
  // }, [isLoading, isError, data]);

  // useEffect(() => {
  //   if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
  //     dispatch({
  //       type: 'INSTITUTES',
  //       institutes: instituteRes.data.response,
  //     });
  //   }
  // }, [instituteRes.isLoading, instituteRes.isError, instituteRes.data]);

  // useEffect(() => {
  //   if (!masterDataRes.isLoading && !masterDataRes.isError && masterDataRes.data) {
  //     dispatch({
  //       type: 'MASTER_DATA',
  //       masterData: masterDataRes.data,
  //     });
  //   }
  // }, [masterDataRes.isLoading, masterDataRes.isError, masterDataRes.data]);

  // useEffect(() => {
  //   if (!token) {
  //     history.push('/');
  //   }
  // }, [token]);

  return (
    <div className="flex flex-col ">

      <div className={showLeftPannel && 'flex  rounded-3xl p-4 gap-x-4'} style={{ background: '#eee' }}>
        {showLeftPannel && <Sidebar />}
        <div
          className="rounded-tl-2xl "
          style={{ 
            width: showLeftPannel ? '82%' : '100%',
            background: '#eee',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
