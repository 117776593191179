import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LOG_OUT } from '../../api/urls';
import useApi from '../../api/useApi';
import { useStateValue } from '../../ContextApi/StateProvider';

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const [{ user }, dispatch] = useStateValue();

  const [response, logOut] = useApi();

  const handleLogout = () => {
    logOut(LOG_OUT, 'GET',null);
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [201, 202, 200, 204].includes(response.status)) {
      Cookies.remove('auth');

      dispatch({
        type: 'TOKEN',
        token: null,
      });
      dispatch({
        type: 'USER',
        user: null,
      });
      window.location.href = "/" ;  // history.push('/');
    }

    if (response.isError) {
    }
  }, [response]);

  return (
    <div
      className={`w-18 bg-white h-full rounded-r-xl text-center customScrollBar flex-col flex`}
      style={{ overflowY: 'scroll', height: '96vh' }}
    >
      <img src="/Ziemtee_logo_red.svg" alt="" className="w-10 h-10 mx-auto mt-4" />
      <div className="my-4 py-3 border-t border-b border-tableHeader">
        <p className="font-semibold">Admin Panel</p>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('Courses') // || location.pathname == '/'
            ? 'bg-primary text-white rounded-3xl'
            : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/Courses`)}>
          Create Courses
        </div>
      </div>
      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('influencerRequests') // || location.pathname == '/'
            ? 'bg-primary text-white rounded-3xl'
            : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/influencerRequests`)}>
             Influencer Requests
        </div>
      </div>
      <div className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('Questions') // || location.pathname == '/'
            ? 'bg-primary text-white rounded-3xl'
            : 'text-gray-500'
        }`}  >
        {/* <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/Questions`)}>
          Add Questions
        </div> */}
      </div> 
      <div className=" mx-auto rounded-lg mt-auto py-4 mb-2 flex flex-col">
        <div>
          {/* <h3 className="text-primary text-sm">{user?.full_name}</h3> */}
          {/* <h4 className="text-textDark text-xs">{user?.email_id}</h4> */}
        </div>
        <button
          className="py-2 px-4 text-xs text-center border border-primary text-primary rounded-lg mx-auto"
          onClick={handleLogout}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};
export default Sidebar;
