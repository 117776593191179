import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../api/useApi';
import { CreateQuestion } from '../../api/urls';
import DropDown from '../../components/UI/DropDown';


function AddNewQuestion({ onClose }) { 
  //
  // const image = useRef();
  //
  // const [selectedImg, setImage] = useState();
  // const [base64Imgstate, setBase64Img] = useState();
  const [formData, setFormData] = useState({ question_desc: '', question_type: "", question_seq_no: ''}) ;  
  const [ServerRes, uploadData] = useApi();
  //
  // useEffect(() => {
  //   if (selectedImg) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(selectedImg);
  //     reader.onloadend = async () => {
  //       const base64Image = reader.result.split(',')[1]; // Extract base64 part
  //       // setShowLoading(true);
  //       // Send base64 image to your Node.js server
  //       // 
  //       setBase64Img( "data:image/jpeg;base64, " + base64Image);
  //       // console.log(" base64Image : ", base64Image )
  //     };
  //   }
  // }, [selectedImg]);
  useEffect(() => { 
    console.log("Save Question data", ServerRes )
    if (!ServerRes.isLoading && !ServerRes.isError && ServerRes.data?.noError) {
      alert('Data Saved Successfully.');  
      clearInputs()
    }
    if (!ServerRes.isLoading && ServerRes.isError) {
      alert('Failed to Save Question details');  
    }
  }, [ServerRes]);

  const clearInputs = () => {
    // image.current.value = null;
    // setBase64Img(null) ;
    setFormData({question_desc: '', question_type: '', question_seq_no: ''});
  }
  const handleDropDownChange = (objdata) => {
    setFormData({ ...formData, question_type : objdata.type });
  };
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const Validations = () => {   
    let showMsg = "" ;
    if( !formData.question_type || formData.question_type == "" ){ showMsg = showMsg + "Please select Question Type.\n" ; }
    if( !formData.question_seq_no || formData.question_seq_no == "" ){ showMsg = showMsg + "Please enter Question Sequence No.\n" ; }
    if( !formData.question_desc || formData.question_desc == "" ){ showMsg = showMsg + "Please enter Question description.\n" ; }
    // if( !base64Imgstate || base64Imgstate == "" ){ showMsg = showMsg + "Please select image for course.\n" ; }
    // 
    return showMsg ;
  }
  const handleSubmit = (e) => {
    e.preventDefault(); 
    // console.log("form data to upload : ", formData )  
    let anyMsg = Validations();
    if(anyMsg){ alert(anyMsg) ; }
    else{
      let ReqData = formData ; // {...formData , imagedata : base64Imgstate }
      // if(ReqData.courseName){ } else { ReqData.courseName = ""; }
      // if(ReqData.courseDesc){ } else { ReqData.courseDesc = ""; }
      // if(ReqData.courseShortdetails){ } else { ReqData.courseShortdetails = ""; }
      // if(ReqData.courseAIAgentQuery){ } else { ReqData.courseAIAgentQuery = ""; }
      // if(ReqData.imagedata){ } else { ReqData.imagedata = ""; }
      // console.log("ReqData : ", ReqData ) 
      uploadData(CreateQuestion, 'POST', ReqData,null);
    }
  } 

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 pt-56"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-10/12 -mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          Add Question
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        />
        <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
        <div className="mt-2 mb-4 w-full relative font-semibold">
            <div className="mt-2 mb-4 w-full relative font-semibold">
              <label htmlFor="category" className="block text-parameter text-sm">
                Question Type
              </label>
              <DropDown
                width={'w-1/3'}
                title={'Select Type'}
                data={[{name:"Big Five Personality Test",type:'1'},{name:"Emotional Intelligence Test",type:'2'}]}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => {
                  handleDropDownChange(x) ;
                  // selectInstitution(x._id);
                }}
                margin={'mt-4 '}
              />
            </div>
          </div>
          <div className="mt-2 mb-4 w-1/2 relative font-semibold">
            <label htmlFor="category" className="block text-parameter text-sm">
              Question Sequence no.
            </label>
            <input
              type="text"
              placeholder=""
              autoComplete="off"
              value={formData.question_seq_no}
              name="question_seq_no"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div> 
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label htmlFor="category" className="block text-parameter text-sm">
            Questions
            </label>
            <input
              type="text"
              placeholder=""
              autoComplete="off"
              value={formData.question_desc}
              name="question_desc"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div> 
          {/* <div className="mt-2 mb-4 w-full relative font-semibold">
            <label htmlFor="category" className="block text-parameter text-sm">
              Course Short Description
            </label>
            <input
              type="text"
              placeholder="Details of syllabus"
              autoComplete="off"
              value={formData.courseDesc}
              // onChange={(e) => setClass(e.target.value)}
              name="courseDesc"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <div class="grid grid-cols-5 gap-4">
            <div>
              <label htmlFor="category" className="block text-parameter text-sm">
                Course Image
              </label>
              <input hidden ref={image} type="file" onChange={(e) => setImage(e.target.files[0])} />
              <button
                className="border w-36 rounded-3xl text-xs font-bold focus:outline-none text-center h-9 bg-primary mt-2 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  image.current.click();
                }}
              >
                Upload Image
              </button> 
            </div>
            <div>
              {base64Imgstate && (<img className="h-24 w-auto mx-auto" src={base64Imgstate} width={50} height={50} ></img>) }
            </div>  
            </div>
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              OnHover details of 4 line with max 30 characters per line
            </label>
            <textarea
              value={formData.courseShortdetails}
              rows="3"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="courseShortdetails"
              onChange={handleInputChange}
              placeholder=""
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              AI Agent Query
            </label>
            <textarea
              value={formData.courseAIAgentQuery}
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="courseAIAgentQuery"
              onChange={handleInputChange}
              placeholder=""
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div> */}

          <div className="flex justify-end">
            {ServerRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <button
                type="submit"
                className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                // onClick={(e) => {
                //   e.preventDefault(); 
                // }}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddNewQuestion;
