

let BASE_URL = 'https://development.admin.ziemtee.com/admin/api';
// BASE_URL = 'http://127.0.0.1:8000/admin/api';

export const LOGIN = `${BASE_URL}/login`;
export const LOG_OUT = `${BASE_URL}/logout`;
export const CareateCourse = `${BASE_URL}/CreateCourse`; 
export const GetCourse = `${BASE_URL}/GetCourse`; 
export const GET_All_Courses =  `${BASE_URL}/AllCourse`; 
export const CreateQuestion  = `${BASE_URL}/CreateQuestion`; 
export const GET_All_Questions = `${BASE_URL}/AllQuestion`; 
export const POST_SubjectTopic =  `${BASE_URL}/SubjectTopic`; 
export const Create_Questions  = `${BASE_URL}/CreateQuestionForCourse`; 

