import React from 'react';
import { BrowserRouter as Router, Route, Switch , Redirect   } from 'react-router-dom';

// Components
import Layout from './components/Layout';

// Pages
import Login from './pages/Login';
import CreateCourse from './pages/CreateCourse';
import { useStateValue } from './ContextApi/StateProvider';
// import Institution from './pages/Institution';
// import Staff from './pages/Staff';
// import Students from './pages/Students';
// import Incident from './pages/Incident';
// import ClassSection from './pages/ClassSection';
// import Owner from './pages/Owner';
// import Principal from './pages/Principal';
// import InstituteLeaves from './pages/InstituteLeaves';
// import Users from './pages/Users';
// import Social from './pages/Social';
import Cookies from 'js-cookie';
import AddQuestions from './pages/AddQuestions' ;
// import Feedbacks from './pages/Feedbacks';
// import FeeStructure from './pages/FeeStructure';
// import StudentMapping from './pages/StudentMapping';
// import PendingFee from './pages/PendingFee';
// import InstituteHoliday from './pages/InstituteHoliday';
// import CronJob from './pages/CronJob';
// import AIModel from './pages/AIModel';


// const redirect = () => { 
//   console.log(" location redirect here : ")
//   window.location = "/" ;
//   return (<> abc</> )
// }

const App = () => { 
  const [{ token }, dispatch] = useStateValue();
const x = Cookies.get('auth');
console.log(" x : ", x , "\n auth : ", token );
  return( 
  <Router>
    <Layout showLeftPannel={x} >
      <Switch> 
        <Route path="/Questions">
          {x ? <AddQuestions /> : <Login />}
        </Route>  
        <Route path="/Courses">
          {x ? <CreateCourse /> : <Login />}
        </Route>

        <Route exact path="/"  > {x ?  <Redirect  to="/Courses" replace={true} state={{ from: location }} />  : <Login />} </Route> 
        <Route path="*" > <Redirect  to="/" replace={true} state={{ from: location }} /> </Route> 
        {/* 
        <Route exact path="/healthcheck">
          <h2>Hello There!</h2>
        </Route> 
        <Route exact path="/cron-job">
          <CronJob />
        </Route> */}
      </Switch>
    </Layout>
  </Router>
) };

export default App;
