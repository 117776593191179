import React, { useState, useEffect } from 'react';
import DropDown from '../components/UI/DropDown';
import useApi from '../api/useApi';
import AddNewQuestion from "./popups/AddNewQuestion"
import { GET_All_Questions } from '../api/urls'; 


function AddQuestions() { 
    const [addQuestion, setAddNewQuestion] = useState(false);
    const [listQuestion, setlistQuestion] = useState([]);
    const [QuestionRes, getQuestion] = useApi();

    useEffect(() => {
      getQuestion(GET_All_Questions, 'GET');
    }, []);

    useEffect(() => {
      if (!QuestionRes.isLoading && !QuestionRes.isError && QuestionRes.data?.noError && QuestionRes.data?.AllQuestion) {
        setlistQuestion(QuestionRes.data.AllQuestion.map((dataobj,index)=>{ 
          if(dataobj.question_type == 1 ){ return {"courseName" : "Type : Big Five Personality Test , Question : " + dataobj.question_desc } }
          else if(dataobj.question_type == 2 ){ return {"courseName" : "Type : Emotional Intelligence Test , Question : " + dataobj.question_desc } }
        }));
      }
    }, [QuestionRes]);
    const onCloseAddPopup = () => { 
      setAddNewQuestion(false) ; 
      getQuestion(GET_All_Questions, 'GET');
    }
   // let addCourse = false ; 
   // let CoursesRes = {isLoading : false }
  //  let listCourses = [{"courseName" : "Type : EQ , Question : Am the life of the party." }]
    return (
        <>
          { addQuestion && ( <AddNewQuestion onClose={() => onCloseAddPopup() } /> ) }
          {/* {addClass && (
            <AddClass onClose={handleCloseClass} refreshList={refreshList} selectedInstitution={selectedInstitution} />
          )}
    
          {addSection && (
            <AddSection
              onClose={() => setAddSection(null)}
              selectedInstitution={selectedInstitution}
              selectedClass={addSection}
            />
          )} */}
    
          <div className="w-4/5 pb-10">
            <div className="w-full bg-white rounded-xl h-auto mb-4">
              <div className="items-center px-6 py-3 border-b border-borderYellow">
                <div className="flex justify-between items-center">
                  <div className="font-bold w-1/3">
                    <p>Personality Analysis Questions</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full h-1/4 px-6">
                {/* <div className="w-full">
                  <DropDown
                    width={'w-1/3'}
                    title={'Select Institution'}
                    data={["test"]}
                    field={'name'}
                    type={'obj'}
                    handleItemClick={(x) => {
                      // selectInstitution(x._id);
                    }}
                    margin={'mt-4 mx-auto'}
                  />
                </div> */}
                {QuestionRes.isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
                ) : listQuestion ? (
                  <div className="w-full py-4">
                    <button
                      className="border py-2 ml-auto w-40 mb-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
                      onClick={() => setAddNewQuestion(true)}
                    >
                      Add Questions
                    </button>
                    <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                      <div className="w-full flex bg-tableHeader rounded-3xl">
                        <div className="flex w-full relative text-left px-4 text-xs font-medium">
                          <div className="w-2/3 border-r-2 py-2 px-2">Questions</div>
                          <div className="w-1/3 py-2 px-2">Action</div>
                        </div>
                      </div>
                      <div className="showScroll" style={{ maxHeight: '60vh' }}>
                        {Array.isArray(listQuestion) &&
                          listQuestion.map((x, idx) => {
                            return (
                              <div className="w-full">
                                <div
                                  className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                                    idx !== listQuestion.length - 1 && 'border-b border-borderYellow'
                                  }`}
                                >
                                  <div className="w-2/3 px-2 text-textDark ">{x?.courseName}</div>
    
                                  <div className={`w-1/3 px-2 flex`}>
                                    {' '}
                                    <button
                                      className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                                      onClick={() => {
                                        // setAddSection(x);
                                      }}
                                    >
                                      View & Edit
                                    </button>
                                    <button
                                      className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-6 mr-3 border-primary text-primary"
                                      onClick={() => {
                                       // handleDeleteClass(x);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) :  null
                // (
                //   <p className="w-full text-center text-xl font-semibold my-10 text-primary">
                //     Select Institution To Get Classes
                //   </p>
                // )
                }
              </div>
            </div>
          </div>
        </>
      );
}


export default AddQuestions ;