import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../api/useApi';
import { CareateCourse , POST_SubjectTopic } from '../../api/urls';


function AddNewSubjects({ onClose, subjectSelected}) { 
  const [selectedSubject, selectSubject] = useState(0);
  const [CourseName, setCourseName ] = useState("");
  const [formData, setFormData] = useState({ new_subject : '' , new_topic : '' });
  const [subjectListServer, setsubjectListServer ] = useState([]);
  const [SubjectTopicRes, SaveLoadSubjectTopic] = useApi();
  //
  useEffect(() => { 
    if( subjectSelected ){ // console.log(" -- subjectSelected : ", subjectSelected );
      if( subjectSelected.courseName && subjectSelected.courseName != "" ){ setCourseName( " in course : " + subjectSelected.courseName );  }
      if( subjectSelected._id && subjectSelected._id != ""){
        let ReqData = { dataType : '0' , dataStr: "", ID:subjectSelected._id }
        SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
      } 
    }
  },[])

  useEffect(() => { 
    if (!SubjectTopicRes.isLoading && !SubjectTopicRes.isError && SubjectTopicRes.data?.noError) {
      setsubjectListServer( SubjectTopicRes.data?.AllSubjectsTopics );
    }
    if (!SubjectTopicRes.isLoading && (SubjectTopicRes.isError || SubjectTopicRes.data?.Error) ) {
      alert('Something unexpected happans.');  
    } else if( !SubjectTopicRes.isLoading && SubjectTopicRes.data?.isSaved ){
      alert('Data Saved Successfully.');  
      setFormData({ new_subject : '' , new_topic : '' });
    } else if( !SubjectTopicRes.isLoading && SubjectTopicRes.data?.isDeleted ){
      alert('Data Deleted Successfully.');   
    }
  },[SubjectTopicRes])
  
  const AddSubject = () => {
    if( formData.new_subject && formData.new_subject != "" ){
      let ReqData = { dataType : '1' , dataStr: formData.new_subject , ID:subjectSelected._id }
      SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
    } else{
      alert("Please enter subject name.")
    }
  }
  const AddTopic = () => { 
    if( formData.new_topic && formData.new_topic != "" && subjectListServer.length > 0 &&  selectedSubject < subjectListServer.length ){
      let subjectId = subjectListServer[selectedSubject]._id ;
      let ReqData = { dataType : '2' , dataStr: formData.new_topic , ID:subjectId }
      SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', ReqData);
    } else {
      alert("Please create subject or select subject if already created\nAnd enter topic name.")
    }
  }
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }; 
  //
  // const image = useRef();
  //
  // const [selectedImg, setImage] = useState();
  // const [base64Imgstate, setBase64Img] = useState();
  // 
  // const [ServerRes, uploadData] = useApi();
  //
  // useEffect(() => {
  //   if (selectedImg) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(selectedImg);
  //     reader.onloadend = async () => {
  //       const base64Image = reader.result.split(',')[1]; // Extract base64 part
  //       // setShowLoading(true);
  //       // Send base64 image to your Node.js server
  //       // 
  //       setBase64Img( "data:image/jpeg;base64, " + base64Image);
  //       // console.log(" base64Image : ", base64Image )
  //     };
  //   }
  // }, [selectedImg]);
  // useEffect(() => { 
  //   // console.log("Save Course data", ServerRes )
  //   if (!ServerRes.isLoading && !ServerRes.isError && ServerRes.data?.noError) {
  //     alert('Data Saved Successfully.');  
  //     clearInputs(); 
  //     reloadpage();
  //   }
  //   if (!ServerRes.isLoading && ServerRes.isError) {
  //     alert('Failed to Save Course details');  
  //   }
  // }, [ServerRes]);

  // const clearInputs = () => {
  //   image.current.value = null;
  //   setBase64Img(null) ;
  //   setFormData({courseName: '', courseDesc: '', courseShortdetails: '', imagedata:"",courseAIAgentQuery:""});
  // }
  
  // const Validations = () => {   
  //   let checkDetRows = formData.courseShortdetails ? formData.courseShortdetails.split('\n') : [] ; 
  //   let showMsg = "" ;
  //   if( !formData.courseName || formData.courseName == "" ){ showMsg = showMsg + "Please enter course name.\n" ; }
  //   if( !formData.courseDesc || formData.courseDesc == "" ){ showMsg = showMsg + "Please enter course description.\n" ; }
  //   if( !base64Imgstate || base64Imgstate == "" ){ showMsg = showMsg + "Please select image for course.\n" ; }
  //   //
  //   if( checkDetRows.length < 1 ){ showMsg = showMsg + "Please enter details to show on hover.\n" ; }
  //   else if( checkDetRows.length > 3 ){ showMsg = showMsg + "We can only show maximum of 3 lines on hover.\n" ; }
  //   else{
  //     let LineNoStr = "" ;
  //     for(let index1 = 0 ; index1 < checkDetRows.length ; index1++ ){
  //       if(checkDetRows[index1].length > 30 ){
  //         LineNoStr = LineNoStr + ( index1 + 1 ) + ","
  //       }
  //     }
  //     if( LineNoStr != "" ){ 
  //       LineNoStr = LineNoStr.substring(0, LineNoStr.length - 1)
  //       showMsg = showMsg + "Line No. " + LineNoStr +" has more then 30 characters to show in details on hover.\n" ;
  //     }
  //   }
  //   return showMsg ;
  // }
  // const handleSubmit = (e) => {
  //   e.preventDefault(); 
  //   // console.log("form data to upload : ", formData )  
  //   let anyMsg = Validations();
  //   if(anyMsg){ alert(anyMsg) ; }
  //   else{
  //     let ReqData = {...formData , imagedata : base64Imgstate }
  //     if(ReqData.courseName){ } else { ReqData.courseName = ""; }
  //     if(ReqData.courseDesc){ } else { ReqData.courseDesc = ""; }
  //     if(ReqData.courseShortdetails){ } else { ReqData.courseShortdetails = ""; }
  //     if(ReqData.courseAIAgentQuery){ } else { ReqData.courseAIAgentQuery = ""; }
  //     if(ReqData.imagedata){ } else { ReqData.imagedata = ""; }
  //     console.log("ReqData : ", ReqData ) 
  //     uploadData(CareateCourse, 'POST', ReqData,null);
  //   }
  // }  
  let topicsList = [ ] ; 
  let SelSubjectId = "0" ;
  if( subjectListServer.length > 0 ){
    if( selectedSubject < subjectListServer.length )
    { topicsList = subjectListServer[selectedSubject].TopicArray ;  SelSubjectId = subjectListServer[selectedSubject]._id ;  }
    else { topicsList = subjectListServer[0].TopicArray ; SelSubjectId = subjectListServer[selectedSubject]._id ;  }
  }
  // console.log(" -- subjectListServer : ", subjectListServer );
  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 pt-56"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-10/12 -mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          Subjects {CourseName}
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        /> 
        <div className='flex flex-row  justify-between px-6' >
          <div className="mt-2 mb-4 ml-6 w-4/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex ">
              Subject
            </label>
            <input
              type="text"
              placeholder="Subject"
              autoComplete="off"
              value={formData.new_subject}
              name="new_subject"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={ AddSubject }
            >
              Add Subject
            </button>
          </div> 
          <div className="mt-2 mb-4 ml-6 w-4/12 relative font-semibold flex flex-row align-middle ">
            <label htmlFor="category" className="block text-parameter text-sm mr-2 items-center flex ">
              Topic
            </label>
            <input
              type="text"
              placeholder="Topic"
              autoComplete="off"
              value={formData.new_topic}
              name="new_topic"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4  py-2.5 focus:outline-none mr-2"
            />
            <button
              className="border py-2 w-40 mr-6 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center bg-primary text-white"
              onClick={AddTopic }
            >
              Add Topic
            </button>
          </div> 
        </div>
        <div className='flex flex-row my-4 justify-around  '> 
          <div className='w-5/12'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Subject Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
              {Array.isArray(subjectListServer) && 
                subjectListServer.map((sub, sub_index) => {
                  return (
                    <div className="w-full cursor-pointer" onClick={()=>{ selectSubject(sub_index); }}>
                      <div
                        className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                          sub_index !== subjectListServer.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-10/12 px-2 text-textDark ">{ selectedSubject == sub_index ? "[ Selected ] " : "" }{sub.SubjectName}</div>
                        <div className={`w-2/12 px-2 flex`}> 
                          <button
                            className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                            onClick={() => {
                              SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', { dataType : '3' , dataStr:'' , ID:sub._id } );
                            }}
                          >
                            Delete
                          </button> 
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              </div>
            </div>
          </div> 
          <div className='w-5/12'> 
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/3 border-r-2 py-2 px-2">Topic Name</div>
                  <div className="w-1/3 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="showScroll" style={{ maxHeight: '60vh' }}>
                {Array.isArray(topicsList) && 
                  topicsList.map((topic, topic_index) => {
                    return (
                      <div className="w-full cursor-pointer"  >
                        <div
                          className={`justify-center flex w-full relative text-left py-2 px-4 text-xs  font-medium items-center ${
                            topic_index !== topicsList.length - 1 && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-10/12 px-2 text-textDark ">{topic}</div>
                          <div className={`w-2/12 px-2 flex`}> 
                            <button
                              className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-2 mr-3 border-primary text-primary"
                              onClick={() => {
                                SaveLoadSubjectTopic(POST_SubjectTopic, 'POST', { dataType : '4' , dataStr:topic , ID: SelSubjectId } );
                              }}
                            >
                              Delete
                            </button> 
                          </div>
                        </div>
                      </div>
                    );
                  })
                } 
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default AddNewSubjects;
