import React, { useState, useEffect } from 'react';
import useApi from '../api/useApi';
import { GET_All_Influencer_Requests , Update_Influencer_Requests , Save_Influencer_Data
} from '../api/urls';

const InfluencerRequests = () => {
  const [requests, setRequests] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [usedEmails, setUsedEmails] = useState([]); // ['jane@example.com' ]);
  const [usedPhones, setUsedPhones] = useState([]); // ['1234567890']);
  const [requestProcessing, setRequestProcessing] = useState(false);
  // 
  const [InfluencerRequestsRes, getInfluencerRequests] = useApi();
  const [updateRequestStatusRes, updateRequestStatus] = useApi();
  const [updateRequestDataRes, updateRequestData] = useApi();


  useEffect(() => {
    getInfluencerRequests(GET_All_Influencer_Requests, 'POST', { 'status' : "all" } );
  }, []);

  useEffect(() => {
    if( !InfluencerRequestsRes.isLoading){ 
      if( InfluencerRequestsRes.isError || InfluencerRequestsRes.data?.Error) { 
        alert('Something unexpected happans.');
      }  else if( InfluencerRequestsRes.data?.noError && InfluencerRequestsRes.data?.requestsData ){
         if( InfluencerRequestsRes.data?.requestsData?.allRequests ){
          setRequests(InfluencerRequestsRes.data?.requestsData?.allRequests);
        }
        if( InfluencerRequestsRes.data?.requestsData?.usedEmails ){
            setUsedEmails(InfluencerRequestsRes.data?.requestsData?.usedEmails);
        }
        if( InfluencerRequestsRes.data?.requestsData?.usedPhones ){
            setUsedPhones(InfluencerRequestsRes.data?.requestsData?.usedPhones);
        }
      }  
    }
  }, [InfluencerRequestsRes]);

  useEffect(() => {
    if( !updateRequestStatusRes.isLoading){ setRequestProcessing(false);
      if( updateRequestStatusRes.isError || updateRequestStatusRes.data?.Error) { 
        alert('Something unexpected happans.');
      }  else if( updateRequestStatusRes.data?.noError ){ 
        //
        if( updateRequestStatusRes.data?.noError && updateRequestStatusRes.data?.requestsData ){
            if( updateRequestStatusRes.data?.requestsData?.allRequests ){
             setRequests(updateRequestStatusRes.data?.requestsData?.allRequests);
           }
           if( updateRequestStatusRes.data?.requestsData?.usedEmails ){
               setUsedEmails(updateRequestStatusRes.data?.requestsData?.usedEmails);
           }
           if( updateRequestStatusRes.data?.requestsData?.usedPhones ){
               setUsedPhones(updateRequestStatusRes.data?.requestsData?.usedPhones);
           }
        }
        //
        if(updateRequestStatusRes.data?.isUpdate){
            alert('Request status updated successfully.');
        } else if( updateRequestStatusRes.data?.alrtMsg && updateRequestStatusRes.data?.alrtMsg != "" ) {
            alert(updateRequestStatusRes.data?.alrtMsg );
        } else {
            alert('Request status update failed.');
        }
      }  
    }
  }, [updateRequestStatusRes]);

  useEffect(() => {
    if( !updateRequestDataRes.isLoading){ setRequestProcessing(false);
      if( updateRequestDataRes.isError || updateRequestDataRes.data?.Error) { 
        alert('Something unexpected happans.');
      }  else if( updateRequestDataRes.data?.noError ){ 
        //
        if( updateRequestDataRes.data?.noError && updateRequestDataRes.data?.requestsData ){
            if( updateRequestDataRes.data?.requestsData?.allRequests ){
             setRequests(updateRequestDataRes.data?.requestsData?.allRequests);
           }
           if( updateRequestDataRes.data?.requestsData?.usedEmails ){
               setUsedEmails(updateRequestDataRes.data?.requestsData?.usedEmails);
           }
           if( updateRequestDataRes.data?.requestsData?.usedPhones ){
               setUsedPhones(updateRequestDataRes.data?.requestsData?.usedPhones);
           }
        }
        //
        if( updateRequestDataRes.data?.isUpdate ){
            alert('Request data updated successfully.');
        }  else {
            alert('Request data update failed.');
        }
      } 
    }
  }, [updateRequestDataRes]);


  // Temporary mock data - replace with actual API call

//   useEffect(() => {
//     setRequests([
//       { 
//         id: 1, 
//         name: 'John Doe', 
//         email: 'john@example.com', 
//         phone: '1234567890', 
//         status: 'pending',
//         channelName: 'JohnDoeTech',
//         followers: '100K',
//         mostViewedContent: 'Tech Review',
//         audienceType: 'Tech Enthusiasts',
//         sponsorshipType: 'Product Review',
//       },
//       { 
//         id: 2, 

//         name: 'Jane Smith', 
//         email: 'john@example.com', 
//         phone: '0987654321', 
//         status: 'pending',
//         channelName: 'JaneLifestyle',
//         followers: '50K',
//         mostViewedContent: 'Lifestyle Vlog',
//         audienceType: 'Young Adults',
//         sponsorshipType: 'Brand Integration', 
//       }
//     ]);

//   }, []);

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleSave = (_id) => {
    setEditingId(null);
    setRequestProcessing(true);
    // Add API call to save changes
    updateRequestData(Save_Influencer_Data, 'POST', { 'ID' : _id, 'dataUpdate' : requests.find(r => r._id === _id) } );
  };

  const handleStatusChange = (_id, newStatus) => { 
    let tempReq = requests.filter(req =>   req._id === _id   ) ;
    // console.log("tempReq : ", tempReq);
    if( tempReq.length > 0 ){
      let alertMsg = "" ; 
      if( usedEmails.includes(tempReq[0].email ) ){ alertMsg = alertMsg + "Email already used by another influencer user.\n"; } 
      if( usedPhones.includes(tempReq[0].phone ) ){ alertMsg = alertMsg + "Phone number already used by another influencer user.\n"; } 
      if( alertMsg != "" ){
        alert(alertMsg); 
      } else {
        setRequestProcessing(true);
        updateRequestStatus(Update_Influencer_Requests, 'POST', { 'ID' : _id, 'status' : newStatus } );
      }
    }else {  console.log("Id not found in data list ??? how this can happen ??? ");   }
    //    
  };


  const handleInputChange = (_id, field, value) => {
    setRequests(requests.map(req =>
      req._id === _id ? {...req, [field]: value} : req
    ));
  };

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      {requestProcessing ? (
        <div className="flex flex-col items-center justify-center p-8 space-y-4 py-52">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          <h2 className="text-xl font-semibold text-gray-700">Processing Your Request</h2>
          <p className="text-gray-500">Please wait while we handle your request...</p>
          <div className="flex space-x-1">
            <div className="w-2 h-2 bg-primary rounded-full animate-bounce"></div>
            <div className="w-2 h-2 bg-primary rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
            <div className="w-2 h-2 bg-primary rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-800">Influencer Requests</h1>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              {requests.length} Request{requests.length > 1 ? "s" : ""}
            </span>
          </div>
          
          <div className="overflow-x-auto">
            {requests.map((request) => (
              <div key={request._id} className="mb-6 p-4 border rounded-lg">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-500">Name</label>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.name}
                        onChange={(e) => handleInputChange(request._id, 'name', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className="mt-1">{request.name}</div>
                    )}
                  </div>

                  <div> 
                    <div className="flex items-center gap-2">
                      <label className={`block text-sm font-medium ${usedEmails.includes(request.email) ? 'text-red-600' : 'text-gray-500'}`}>
                        Email
                      </label>
                       { requests.filter(r => r.email == request.email).length > 1 &&
                        <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-0.5 rounded-full">
                            {requests.filter(r => r.email == request.email).length}
                        </span> 
                        }
                    </div>
                    {editingId === request._id ? (
                      <input
                        type="email"
                        value={request.email}
                        onChange={(e) => handleInputChange(request._id, 'email', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className={`mt-1 ${usedEmails.includes(request.email) ? 'text-red-600 font-semibold' : ''}`}>
                        {request.email}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="flex items-center gap-2">
                      <label className={`block text-sm font-medium ${usedPhones.includes(request.phone) ? 'text-red-600' : 'text-gray-500'}`}>
                        Phone
                      </label>
                      { requests.filter(r => r.phone == request.phone).length > 1 &&
                        <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-0.5 rounded-full">
                            {requests.filter(r => r.phone == request.phone).length}
                        </span> 
                      }
                    </div>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.phone}
                        onChange={(e) => handleInputChange(request._id, 'phone', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className={`mt-1 ${usedPhones.includes(request.phone) ? 'text-red-600 font-semibold' : ''}`}>
                        {request.phone}
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Channel Name</label>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.channelName}
                        onChange={(e) => handleInputChange(request._id, 'channelName', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className="mt-1">{request.channelName}</div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Followers</label>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.followers}
                        onChange={(e) => handleInputChange(request._id, 'followers', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className="mt-1">{request.followers}</div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Most Viewed Content</label>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.mostViewedContent}
                        onChange={(e) => handleInputChange(request._id, 'mostViewedContent', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className="mt-1">{request.mostViewedContent}</div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Audience Type</label>
                    {editingId === request._id ? (
                      <input
                        type="text"
                        value={request.audienceType}
                        onChange={(e) => handleInputChange(request._id, 'audienceType', e.target.value)}
                        className="border rounded px-2 py-1 w-full"
                      />
                    ) : (
                      <div className="mt-1">{request.audienceType}</div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Sponsorship Type</label>
                    {editingId === request._id ? (
                      <select
                        value={request.sponsorshipType}
                        onChange={(e) => handleInputChange(request._id, 'sponsorshipType', e.target.value)}
                        className="border rounded px-2 py-1 w-full bg-white"
                      >
                        <option value="Paid">Paid</option>
                        <option value="Commission">Commission</option>
                      </select>
                    ) : (
                      <div className="mt-1">{request.sponsorshipType}</div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-500">Status</label>
                    <div className="mt-1">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${request.status === 'accepted' ? 'bg-green-100 text-green-800' : 
                          request.status === 'rejected' ? 'bg-red-100 text-red-800' : 
                          'bg-yellow-100 text-yellow-800'}`}>
                        {request.status}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex justify-end space-x-4"> 
                  {request.status === 'pending' && (
                    <>
                        {editingId === request._id ? (
                        <button
                            onClick={() => handleSave(request._id)}
                            className="text-indigo-600 hover:text-indigo-900"
                            >
                            Save
                            </button>
                        ) : (
                            <button
                            onClick={() => handleEdit(request._id)}
                            className="text-indigo-600 hover:text-indigo-900"
                            >
                            Edit
                            </button>
                        )}
                      <button
                        onClick={() => handleStatusChange(request._id, 'accepted')}
                        className="text-green-600 hover:text-green-900"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleStatusChange(request._id, 'rejected')}
                        className="text-red-600 hover:text-red-900"
                      >
                        Reject
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default InfluencerRequests;
